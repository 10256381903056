<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded: loaded }">
        <div id="sticky-heading-container" class="heading-container sticky">
            <h1 class="title">{{$t('pages.workProviderOffers.paid.title')}}</h1>
            <div class="cta-container with-button-group">
                <div :class="['cta cta-primary cta-slim', { loading }]" @click="setup_create_offer()">{{$t('pages.workProviderOffers.shared.ctas.create')}}</div>
            </div>
        </div>
        
        <div id="with-sticky-header" class="with-sticky-header">
            <div class="table-container scrollable with-sticky-header nomargin-bottom">
                <table class="nomargin-bottom">
                    <tr>
                        <th>{{$t('admin.workProviderOffers.th.name')}}</th>
                        <th>{{$t('table.workProviderOffers.th.location')}}</th>
                        <th>{{$t('table.workProviderOffers.th.primary-contact')}}</th>
                    </tr>
                    
                    <tr v-for="offer in offers" :key="offer.info._id" @click="$utils._navigate_to_name_with_params('work-provider-offer', { id: offer.info._id, state: 'consult' })">
                        <td :class="['bold no-wrap']">{{ format_offer_name(offer) }}</td>
                        <td class="no-wrap">{{ compute_location_label(offer) }}</td>
                        <td class="no-wrap">{{ offer.contact_name }}</td>
                    </tr>
                    
                    <tr v-for="unpublished_offer in unpublished_offers" :key="unpublished_offer._id" @click="$utils._navigate_to_name_with_params('create-work-provider-offer', { id: unpublished_offer._id })">
                        <td class="bold no-wrap">{{ get_name_for_unpublished_offer(unpublished_offer.json) }}</td>
                        <td colspan="2">{{ $t('table.workProviderOffers.td.unpublished_offer') }}</td>
                    </tr>
                    
                    <tr class="no-hover">
                        <td v-if="!loaded" class="loading" colspan="3"></td>
                        <td class="bold" v-if="loaded && offers.length === 0 && unpublished_offers.length === 0" colspan="8">{{$t('table.workProviderOffers.empty')}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Layout from '../../../../components/Layout.vue';

import { bus } from '../../../../main';

export default {
    name: 'WorkProviderOffers',

    components: {
        Layout,
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            routeName: 'offers',
            classes: { dashboard: true },

            offers: [],
            unpublished_offers: [],
            loaded: false,
            loading: false,
            view: 'simple'
        }
    },

    computed: {
        ...mapGetters([
            'currentProfile',
            'currentProfileType',
            'has_payment_method'
        ]),

        view_is_detailed: function() {
            const self = this; return self.view === 'detailed';
        }
    },

    methods: {
        ...mapActions([
            'set_payment_method_needed_modal_state',
        ]),

        format_offer_name: function(offer) {
            return `${offer.info._id.substring(offer.info._id.length - 4, offer.info._id.length).toUpperCase()} - ${offer.info.name}`
        },
        
        get_published_offers: function() {
            const self = this;

            self.$axios
                .get(`work-provider/offers/${self.currentProfileType}/published`)
                .then(response => {
                    if(response.status == 200){
                        self.offers = response.data;
                        self.loaded = true;

                        self.$nextTick(() => {
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }

                    self.loaded = true;
                });
        },

        get_unpublished_offers: function() {
            const self = this;

            self.$axios
                .get(`work-provider/offers/${self.currentProfileType}/unpublished`)
                .then(response => {
                    if(response.status == 200){
                        self.unpublished_offers = response.data;

                        self.$nextTick(() => {
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        compute_location_label: function(offer) {
            const self = this;

            if(offer.info.workLocation === 'remote') {
                return self.$t('language.words.remote.cap');
            }

            if(offer.info.workLocation === 'regular') {
                if(offer.addresses.length === 1) {
                    return offer.addresses[0]
                } else {
                    return self.$t('table.generic.td.locations.multiple');
                }
            }

            if(offer.info.workLocation === 'both') {
                if(offer.addresses.length === 1) {
                    return `${offer.addresses[0]} ${self.$t('language.words.and')} ${self.$t('language.words.remote.lower')}`
                }
                return self.$t('table.generic.td.locations.multipleAndRemote');
            }
        },

        setup_create_offer: function() {
            const self = this;

            self.loading = true;
            self.$axios
                .post(`work-provider/offer/unpublished`)
                .then(response => {
                    if(response.status == 200){
                        self.loading = false;

                        const id = response.data;
                        self.$utils._navigate_to_name_with_params('create-work-provider-offer', { id })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                    
                    self.loading = false;
                });
        },

        get_name_for_unpublished_offer: function(json) {
            if(json !== '') {
                const data = JSON.parse(json);

                if(data && data.name) {
                    return data.name;
                }
            }

            return 'N/A'
        }
    },

    mounted() {
        const self = this; 
        self.get_published_offers();
        self.get_unpublished_offers();
    }
}
</script>

